/* eslint-disable no-undef */
import './App.css';
import './counter.css';
import React, { useEffect, useMemo, useState } from 'react';
import os from './assets/opensea.png';
import twitter from './assets/twitter-.png';
import home from './assets/home-button.png';
import logo from './assets/logo.png';
import gif from './assets/gif.mp4';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork, useWaitForTransaction } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther, webSocket } from 'viem';
import Countdown from 'react-countdown';
import { sepolia } from 'wagmi/chains'

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload(true);
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

const ops = () => {
	window.open("https://testnets.opensea.io/collection/test-14225");
}

const tweet = () => {
	window.open("https://x.com/yanusmobile");
}

const homeLink = () => {
	window.open("#");
}

let ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price1",
				"type": "uint256"
			}
		],
		"name": "setPrice1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price2",
				"type": "uint256"
			}
		],
		"name": "setPrice2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenID",
				"type": "uint256"
			}
		],
		"name": "getTokenStatus",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "mintCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price1",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "specialMintCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0xaaF90EB8b21a0bdeEAa6ED2f5b39349C0aF8537C";
//let address = "0xE7268C3BC66b4dA84925D16110aE81391F26061d";

const maxSupply = 555;

const Home = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const { switchNetwork } = useSwitchNetwork()

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const transport = webSocket('wss://sepolia.infura.io/ws/v3/9aad89c8e515457ab8b7805f5da593ea')

	const publicClient = createPublicClient({
		chain: sepolia,
		transport, /*: http()*/
	})

	const [_totalSupply, settotalSupply] = useState(0)
	const [statusError, setstatusError] = useState("")
	const [statusLoading, setstatusLoading] = useState("")
	const [success, setsuccess] = useState("")
	const [statusErrorWL, setstatusErrorWL] = useState("")
	const [statusLoadingWL, setstatusLoadingWL] = useState("")
	const [successWL, setsuccessWL] = useState("")
	const [nftMintingAmount, setnftMintingAmount] = useState(1)
	const [_publicMintMsg, set_publicMintMsg] = useState("Mint Here")
	const [_cost, set_publicSaleCost] = useState("")
	const [_wlcost, set_wlcost] = useState("")
	const [_max_per_wallet, set_max_per_wallet] = useState(10)
	const [_owner, set_owner] = useState("")
	const [myNFTWallet, setmyNFTWallet] = useState(0)
	const [_whitelist_mint_status, set_whitelist_mint_status] = useState("")
	const [_public_mint_status, set_public_mint_status] = useState("")
	const [_whitelistLimitPerWallet, set_whitelistLimitPerWallet] = useState("")
	const [_whitelistCount, set_whitelistCount] = useState("")
	const [_whitelistLimit, set_whitelistLimit] = useState("")
	const [_MAX_SUPPLY, set_MAX_SUPPLY] = useState("")
	const [_wlEligible, setWLEligible] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_freeMinLimit, set_OverallFreeMintLimit] = useState(600);
	const [_stateNow, set_stateNow] = useState(Date.now());
	const [_wlMinted, setWLMinted] = useState();
	const [_mintCount, set_mintCount] = useState(0);
	const [transactionHash, setTransactionHash] = useState(null);
	const apiUrl = process.env.REACT_APP_API_URL;

	const { data: transactionReceipt, isLoading: transactionLoading, isError: transactionError } = useWaitForTransaction({
		hash: transactionHash
	});

	const price1 = 0.01; // Replace with the actual price1 value from your contract
	const price2 = 0.02; // Replace with the actual price2 value from your contract

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}
	//........................................//
	const contract = {
		address: address,
		abi: ABI
	}

	async function handleConnect() {
		if (chain.id !== 11155111) {
			switchNetwork(11155111)
		}

		var data = await getBalance();
		setmyNFTWallet(Number(data.data));
		console.log("myNFTWallet :" + data.data);
		setConnected(true);
	}

	const { refetch: getTotalSupply } = useContractRead({
		...contract,
		functionName: 'totalSupply',
	})

	const { refetch: getCost } = useContractRead({
		...contract,
		functionName: 'publicSaleCost',
	})

	const { refetch: getBalance } = useContractRead({
		...contract,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})


	useEffect(() => {

		console.log("apiUrl :" + apiUrl);

		async function fetchData() {
			var data = await getTotalSupply();

			settotalSupply(Number(data.data))
			console.log("totalSupplyFromUseffect : " + data.data)

		}
		async function fetchData2() {

			var data1 = await getBalance();
			setmyNFTWallet(Number(data1.data));
			console.log("myNFTWallet :" + data1.data);
		}

		async function fetchData3() {

			var data2 = await getCost();
			set_publicSaleCost(Number(data2.data) / 10 ** 18);
			console.log("publicSaleCost :" + data2.data);
		}

		if (_connected) {
			fetchData();
			fetchData2();
			fetchData3();
		}

		if (transactionReceipt) {
			if (transactionReceipt.status === 1) {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				setTimeout(() => {
					window.location.reload(true);
				}, 5000);
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		}

		// eslint-disable-next-line no-use-before-define
	}, [_connected, transactionReceipt, getBalance, getCost, getTotalSupply]);

	const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply' },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'owner' },
			{ ...contract, functionName: 'publicSaleCost' },
			{ ...contract, functionName: 'PUBLIC_MAX_SUPPLY' },
			{ ...contract, functionName: 'paused' },
			{ ...contract, functionName: 'mintCount' },
		]
	},)

	useMemo(() => {

		if (isSuccess === true) {
			settotalSupply(Number(data[0].result))
			set_max_per_wallet(Number(data[1].result))
			set_owner(data[2].result)
			set_publicSaleCost(Number(data[3].result) / 10 ** 18); // Convert WEI to ETH			
			set_MAX_SUPPLY(data[4].result)
			set_public_mint_status(data[5].result)
			set_mintCount(Number(data[6].result))
		}
	}, [_totalSupply, data, isSuccess])



	const { writeAsync } = useContractWrite({
		...contract,
		functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})
	//........................................//

	async function onPlus() {

		console.log("_public_mint_status :" + _public_mint_status);
		var nftIhave = nftMintingAmount + myNFTWallet;


		console.log("myNFTWallet : " + myNFTWallet);
		console.log("nftMintingAmount : " + nftMintingAmount);

		console.log("nftIhave : " + nftIhave);


		console.log("1");
		if (!_public_mint_status) {
			console.log("2");

			if (nftIhave < _max_per_wallet) {
				//if (nftIhave < 1) {
				console.log("3");

				setnftMintingAmount(nftMintingAmount + 1);
				console.log("4");


				//}
			}
		}


	}

	async function onMinus() {
		if (nftMintingAmount != 1) {
			setnftMintingAmount(nftMintingAmount - 1)
		}
	}

	/*async function onMint() {
		try {
			var mintCost;
			var fullTotalSupply = 0;
			fullTotalSupply = (nftMintingAmount) + (_totalSupply);
			console.log("fullTotalSupply : " + fullTotalSupply);


			if (_owner === walletAddress) {

				mintCost = '0'

			} else {

				if (fullTotalSupply <= 0) {
					mintCost = '0'
				} else

					if (_public_mint_status) {
						//mintCost =  parseFloat(_cost)
						mintCost = _cost;
						console.log("mintCostB : " + mintCost);
					}
			}

			mintCost = (mintCost * nftMintingAmount).toString();
			console.log("nftMintingAmountA : " + nftMintingAmount)

			console.log("mintCostA : " + mintCost);


			setstatusLoading(true)
			setstatusError(false)

			var res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(mintCost)// mintCost
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)

			}
		}
		catch (e) {
			console.log(e)
			setstatusError(true)
			setstatusLoading(false)

		}
	}*/

	/*async function onMint() {
		try {
			let mintCost = 0;

			if (_owner === walletAddress) {
				mintCost = 0;
			} else {
				for (let i = 0; i < nftMintingAmount; i++) {
					const currentMintCount = _mintCount + i;

					if (currentMintCount < 2700) {
						mintCost += 0;
					} else if (currentMintCount < 5700) {
						mintCost += price1;
					} else if (currentMintCount < 9700) {
						mintCost += price2;
					}
				}
			}

			const totalMintCost = mintCost.toString();
			console.log("nftMintingAmount: " + nftMintingAmount);
			console.log("totalMintCost: " + totalMintCost);

			setstatusLoading(true);
			setstatusError(false);

			const res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(totalMintCost)
			});
			const result = await publicClient.waitForTransactionReceipt(res);
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		} catch (e) {
			console.log(e);
			setstatusError(true);
			setstatusLoading(false);
		}
	}*/

	async function onMint() {
		try {
			let mintCost = 0;

			if (_owner === walletAddress) {
				mintCost = 0;
			} else {
				for (let i = 0; i < nftMintingAmount; i++) {
					const currentMintCount = Number(_mintCount) + i;

					if (currentMintCount < 2700) {
						mintCost += 0;
					} else if (currentMintCount < 5700) {
						mintCost += price1;
					} else if (currentMintCount < 9700) {
						mintCost += price2;
					}
				}
			}

			const totalMintCost = mintCost.toString();
			console.log("nftMintingAmount: " + nftMintingAmount);
			console.log("totalMintCost: " + totalMintCost);

			setstatusLoading(true);
			setstatusError(false);

			const res = await writeAsync({
				args: [nftMintingAmount],
				value: parseEther(totalMintCost)
			});

			const result = await publicClient.waitForTransactionReceipt(res);
			if (result.status !== 'success') {

				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);

			} else {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);

			}
		} catch (e) {
			console.log(e);
			setstatusError(true);
			setstatusLoading(false);
		}



	}



	return (
		<div class="allWrap">
			<div class="light">
				<div class="cont">
					<div class="headers">

						<div class="headers2">

							<div class="logo"><img class="logoPic" src={logo} /></div>

							<div class="right">

								<div class="icons">
									<div class="socialIcon"><img onClick={ops} src={os} /></div>
									<div class="socialIcon"><img onClick={tweet} src={twitter} /></div>
								</div>

								<div class="connect2">
									<Web3Button />
								</div>

							</div>

						</div>

					</div>

					<div class="introduction">

						<div class="in2">
							<div class="intro">
								Yanus NFT Gensis Pass Collection
							</div>

							<div class="intro2">
								<p></p>
								<p>Total Limited Pass: 10, 000</p>
								<p>Type of Pass: Normal Pass, Black Pass</p>
								<p>Max mint per wallet: 10</p>
								<p>Chain: Bnb Chain</p>
							</div>

							<div className='pricesM'>
								<div>Price:</div>
								<div className='prices'>
									<p>0~3000 —> FREE MINT</p>
									<p>3001~6000 —> 0.01 BNB</p>
									<p>6001~10000 —> 0.02 BNB</p>
								</div>
							</div>

							<div class="nftblockWalletConnectedALL">
								{walletAddress === undefined ?
									<div class="walletConnect">

										<button onClick={() => { open() }} class="wallet3" >MINT NOW</button>

									</div>
									:
									null}

								{_wlMinted < 1 ?
									(<div class="walletConnect">

										{_wlEligible ?
											(<button onClick={wlMint} class="btnfos-0-3" >Whitelist Mint</button>) : null}

									</div>) : null}

								<div>

									{statusErrorWL ? (
										<div class="errorMessage2">
											<div>Sorry, something went wrong <br /> please try again later</div>
										</div>)
										: null}

									{statusLoadingWL ? (
										<div class="loadingContainer">
											<div class="loadingText2">Minting your NFT</div>
										</div>)
										: null}

									{successWL ? (
										<div class="successfully2">MINTING SUCCESSFUL!</div>
									)
										: null}

								</div>
							</div>
						</div>

						{walletAddress === undefined ?

							(<div class="nftPicDiv">
								<video class="nftPic" src={gif} alt='gif' autoPlay loop playsInline muted />
							</div>) : (

								(<div class="mintDiv">
									<div class="totalSupply">{_totalSupply} / 10, 000</div>

									{(_mintCount < 2700) ? (
										<div className='price'>Free Mint</div>
									) : (_mintCount < 5700) ? (
										<div className='price'>0.01</div>
									) : (_mintCount < 9700) ? (
										<div className='price'>0.02</div>
									) : null}

									<div class="minting_count_button">

										<div class="center">

											<button onClick={onMinus} class="btnfos-0-2" type="submit">-</button>

										</div>

										<div>
											<div class="nftminter2">{nftMintingAmount}</div>
										</div>


										<div class="center">
											<button onClick={onPlus} class="btnfos-0-2" type="submit">+</button>

										</div>
									</div>

									{_max_per_wallet === myNFTWallet ?

										(<div class="price"><div>Limit Reached!</div></div>) :
										(<div class="price"><div>You can mint {Number(_max_per_wallet) - Number(myNFTWallet)} more</div></div>)}


									{_max_per_wallet === myNFTWallet ?
										<div class="mintbuttondiv">
											{/* <form onSubmit={this.onSubmit2}> */}
											<button class="btnfos-0-3-Dis" disabled onClick={onMint}>
												{_publicMintMsg}</button>
											{/* </form> */}
										</div> :
										<div class="mintbuttondiv">
											{/* <form onSubmit={this.onSubmit2}> */}
											<button class="btnfos-0-3" onClick={onMint}>
												{_publicMintMsg}</button>
											{/* </form> */}

										</div>
									}
									<div>

										{statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong <br /> please try again later</div>
											</div>)
											: null}

										{statusLoading ? (
											<div class="loadingContainer">
												<div class="loadingText">Minting your NFT</div>
											</div>)
											: null}

										{success ? (
											<div class="successfully">Minting Successful!</div>
										)
											: null}

									</div>


								</div>

								)
							)}
					</div>


					<div class="intro2Main">
						<div class="intro2">
							<div class="intro">
								Benefits
							</div>
							<p></p>
							<p>🔘 FREE Yanus Phone if you luckily minted BLACK PASS </p>
							<p>🔘 Priority Shipping/Delivery</p>
							<p>🔘 Early Access to all BETA of Yanus web3 ecosystem</p>
							<p>🔘 Extra 1000 Yanus Rewards Points</p>
							<p>🔘 Airdrop from Partnerships</p>
						</div>
					</div>

				</div>

			</div >
		</div >
	)

}
export default Home;
